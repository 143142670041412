<template>
  <div style="padding: 0.41rem">
    <el-row :gutter="50">
      <el-col :span="10">
        <el-row>
          <el-col v-for="(item, index) in statistics" :key="index">
            <div style="height: 0.48rem;width:1.44rem;border-radius: 0.05rem" :style="'background:'+ item.background">
              <div style="width: 0.48rem;height: 0.48rem;float: left;text-align: center;border-radius: 0.05rem;line-height:0.48rem;"
                   :style="'background:'+ item.iconBackground">
                <img :src="item.icon" style="width: 0.28rem;height: 0.28rem;vertical-align: middle;"/>
              </div>
              <div style="float: right;color: #FFF;text-align: center;height: 0.48rem;width:0.96rem">
                <p style="font-size: 0.16rem;font-weight: bold;margin-top: 0.06rem">{{ item.count }}</p>
                <p style="color: #021546;font-size: 0.12rem;">{{ item.label }}</p>
              </div>
            </div>
            <div style="height: 0.2rem;width: 1rem" v-if="statistics.length - 1 > index"></div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="14">
        <el-row>
          <el-col v-for="(item, index) in ratio" :key="index" :span="12">
            <div style="width: 0.89rem;height: 0.89rem;">
              <annular :value="item.count" :speed="1"></annular>
              <p style="color: #00A2FF;font-size: 0.14rem;text-align: center;margin-top: 0.1rem">{{ item.label }}</p>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {
    annular: () => import('./Gauge')
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      statistics: [
        {
          label: '在校学生人数',
          code: 'currentStudentNum',
          count: 0,
          background: '#576FDD',
          iconBackground: '#758FFE',
          icon: '/static/images/student2.png'
        },
        {
          label: '在校教职工数',
          code: 'currentTeacherNum',
          count: 0,
          background: '#06BAD5',
          iconBackground: '#08DFFE',
          icon: '/static/images/faculty.png'
        }
      ],

      ratio: [
        {
          label: '学生在校率',
          code: 'studentProportion',
          count: 0
        },
        {
          label: '教职工在校率',
          code: 'teacherProportion',
          count: 0
        }
      ]
    }
  },

  watch: {
    data (newData) {
      this.init(newData)
    }
  },

  mounted () {
    this.init(this.data)
  },

  methods: {
    init (data) {
      this.statistics.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.count = data[item.code]
        }
      })
      setTimeout(() => {
        this.ratio.forEach(item => {
          if (typeof data[item.code] !== 'undefined') {
            item.count = (Number(data[item.code]) * 100).toFixed(2)
            item.count = parseFloat(item.count)
          }
        })
      }, 30)
    }
  }
}
</script>
